import React, { useEffect, useState } from "react";
import Bidding from "../../components/Bidding";
import SelectCrypto from "../../components/SelectCrypto";
import EnterAmount from "./EnterAmount";
import Confirm from "./Confirm";
import requestHandler from "../../actions/httpClient";
import { useLocation, useNavigate } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux";
import { userStatusCreator } from "../../actions/getUserStatus";
import LoaderScreen from "../../components/LoaderScreen";
const steps = ["Select crypto", "Enter amount", "Confirm"];

const SellCrypto = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [marketData, setMarketData] = useState([]);
  const [order, setOrder] = useState("DSC");
  const [value, setValue] = useState();
  const [price, setPrice] = useState();
  // const [currencyBalance, setCurrencyBalance] = useState()
  const [decimalPrecision, setDecimalPrecision] = useState();
  const [coinBalance, setCoinBalance] = useState()
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()
  const location = useLocation()
  const dispatch = useDispatch()
  const { userStatus } = useSelector((state) => { return state.getUserStatus });

  useEffect(() => {
    getRegion();
    if (userStatus.length === 0) {
      dispatch(userStatusCreator())
    }
    if (userStatus?.kyc_verification && userStatus?.kyc_verification !== 5) {
      navigate("/user-kyc-verification");
    }
  }, []);

  const getRegion = async () => {
    try {
      const regionPayload = await requestHandler("region", "post");
      for (let i = 0; i < regionPayload.data?.data.length; i++) {
        if (regionPayload?.data?.data[i].slug == process.env.REACT_APP_INTERNATIONAL_MARKETS) {
          getMarketsData(regionPayload?.data?.data[i]?.id);
        };
      };
    }
    catch (e) {
    };
  };

  const handleOrder = (e) => {
    const sorted =
      order && order === "ASC"
        ? marketData.sort((a, b) =>
          a["name"].toLowerCase() > b["name"].toLowerCase() ? 1 : -1
        )
        : marketData.sort((a, b) =>
          a["name"].toLowerCase() < b["name"].toLowerCase() ? 1 : -1
        );
    const sortingType = order && order === "ASC" ? "DSC" : "ASC";
    setMarketData(sorted);
    setOrder(sortingType);
  };

  const getMarketsData = async (id) => {
    try {
      setLoading(true)
      let data = {
        type: "allMarkets",
        region: id,
        signature: localStorage.getItem('signature')
      };
      const payload = await requestHandler("get_markets", "post", data, "jwt_token");
      setMarketData(payload.data?.data[0]?.markets)

      if (location?.state?.symbol && location?.state?.symbol != null) {
        exchangeCreator(location.state.symbol).then(() => {
          payload.data?.data[0]?.markets.filter(x => x.slug === location.state.symbol).map(y => setValue(y))
          setLoading(false)
          setActiveIndex(1)

        })
      } else {
        setLoading(false)
      }
      setMarketData(
        payload.data?.data[0]?.markets.sort((a, b) =>
          a["name"].toLowerCase() > b["name"].toLowerCase() ? 1 : -1
        )
      );

    }
    catch (e) {
      setLoading(false)
    };
  }

  const exchangeCreator = async (slug) => {
    const formData = {
      market_slug: slug,
      signature: localStorage.getItem("signature")
    }
    try {
      const payload = await requestHandler('getUserCurrentMarketBalance', 'post', formData, 'jwt_token')
      // setCurrencyBalance(payload.data.data.currencyBalance)
      setCoinBalance(payload.data.data.coinBalance)
      setDecimalPrecision(payload.data.data.amountDecimalPrecision)
    }
    catch (error) {
    }
  }

  return (
    <>
      <Bidding title="Sell crypto" items={steps} activeIndex={activeIndex}>
        {loading ? <LoaderScreen /> : <>

          {activeIndex === 0 && <SelectCrypto goNext={(value) => { setValue(value); exchangeCreator(value.slug); setActiveIndex(1) }} handleOrder={handleOrder} marketData={marketData} />}
          {activeIndex === 1 && (
            <EnterAmount
              goBack={() => setActiveIndex(0)}
              goNext={(price) => { setPrice(price); setActiveIndex(2) }}
              value={value}
              coinBalance={coinBalance}
              decimalPrecision={decimalPrecision}
            />
          )}
          {activeIndex === 2 && (
            <Confirm
              goBack={() => setActiveIndex(1)}
              goFinish={() => setActiveIndex(0)}
              value={value}
              coinBalance={coinBalance}
              price={price}
            />
          )}
        </>}
      </Bidding>
    </>
  );
};

export default SellCrypto;
