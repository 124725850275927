import React from "react";
import cn from "classnames";
import styles from "./Notice.module.sass";

const Notice = ({ className }) => {
  return (
    <>
      <div className={cn(className, styles.cards)}>
        <div className={styles.details}>
          <div className={styles.price}>The risk of investing</div>
          <div className={styles.money}>Bitdenex.com is a website which provides an overview of the trading options and trading platform of Bitdenex per region. Each region applies its own local applicable law and regulations to the local traders, which shall comply with the applicable law.</div>
          <br />
          <div className={styles.money}>Bitdenex International is operated by Coindenex LLC - license no. 922 LLC 2021, which
            is authorized and regulated by the Financial Services Authority of St. Vincent & The
            Grenadines.
          </div></div>
      </div>
    </>
  );
};

export default Notice;
