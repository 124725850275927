import cn from "classnames";
import styles from "./Releases.module.sass";
import Item from "./Item";
import TVChartContainer from "../../../components/TVChartContainer";

const items = [
  {
    id: '0',
    title: "Sign up for Bitdenex",
    currency: "Ripple",
    content: "First of all, you need to sign up for an Bitdenex account. Your Bitdenex account is a secure place to store your Bitcoins. Unfortunately, you can’t exchange your local currency into bitcoin without registered account. Based on the current laws and regulations, you must have a registered account to be able to trade cryptocurrency  The KYC and AML policy does not allow to trade anonymously. That’s the main reason why you have to sign up for an account, unless you only want to try the FREE account. For the free account, you only need an email address.",
    category: "green",
    categoryText: "Get Started",
    image: "/images/content/BuyAndSellPages/Sign-up-for-Bitdenex.jpg",
    image2x: "/images/content/BuyAndSellPages/Sign-up-for-Bitdenex.jpg",
    url: "/exchange/XRP-USDT",
    preUrl: "/sign-up",
  },
  {
    id: '1',
    title: "Verify your Bitdenex Account",
    currency: "Ripple",
    content: "You need to verify your Bitdenex account, after you sign up. In order to verify your Bitdenex account, you need to provide some documentation.  When all the details are correct, your Bitdenex account will be open to trade. Once the verification steps are complete, you can start trading. ",
    category: "green",
    categoryText: "verify your bitdenex account",
    image: "/images/content/BuyAndSellPages/Verify-your-Bitdenex-Account.jpg",
    image2x: "/images/content/BuyAndSellPages/Verify-your-Bitdenex-Account.jpg",
    url: "/user-kyc-verification",
    preUrl: "/sign-up",
  },
  {
    id: '2',
    title: "Buy & Sell Ripple",
    currency: "Ripple",
    content: "To complete your account, you need to do some verification steps before you can fully use your registered Bitdenex account. Go through the verification steps and add the necessary documents. After you add the documents, we will check those and complete the verification after all documents are correct. We always show you the current exchange rate before you trade. Bitdenex don’t determine the prices. The traders determine the prices, so make sure you check the rate before you place your order or limit order. ",
    category: "green",
    categoryText: "start trading XRP",
    image: "/images/content/BuyAndSellPages/Buy-&-Sell-ripple-xrp.jpg",
    image2x: "/images/content/BuyAndSellPages/Buy-&-Sell-ripple-xrp.jpg",
    url: "/exchange/XRP-USDT",
    preUrl: "/sign-up",
  },
];

const Releases = ({ scrollToRef }) => {
  const currentMarketDetails = [{ priceDecimalPrecision: 4 }];

  return (
    <div
      className={cn("section-bg section-mb0", styles.releases)}
      ref={scrollToRef}
    >
      <div className={cn("container", styles.container)}>
        <div className={styles.widgetList}>
          <TVChartContainer
            currentMarketDetails={currentMarketDetails}
            symbol={"xrp-usdt"}
          />
        </div>
        <div className={styles.copyright}>
          <a href="https://www.tradingview.com/" rel="noopener nofollow" target="_blank"><span className="blue-text">Track all markets on TradingView</span></a>
        </div>
      </div>
      <div className={cn("container", styles.container)}>
        <div className={styles.list}>
          {items.map((x, index) => (
            <Item className={styles.item} item={x} key={index} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Releases;
