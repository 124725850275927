import React, { useState } from "react";
import cn from "classnames";
import styles from "./Currency.module.sass";
import Icon from "../../../components/Icon";
import { getDigitsAfterDecimal } from '../../../components/helper'
import { Link } from "react-router-dom";
import ExchangeSkeleton from "../../../components/Skeleton/ExchangeSkeleton";

const navigation = [
  {
    "currencyId": 1,
    "name": "EURO"
  },
  {
    "currencyId": 3,
    "name": "USDT"
  },
  {
    "currencyId": 2,
    "name": "BTC"
  }
];

const Currency = ({
  allMarketsData,
  marketLoading,
  dataMarkets,
  setDataMarkets,
  tradingType,
}) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [currencyId, setCurrencyId] = useState(1);
  const [search, setSearch] = useState("");
  const [order, setOrder] = useState({ column: null, order: 'ASC' });
  const [activeColumn, setActiveColumn] = useState({ key: "", value: false });

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  const handleSort = (columnName) => {
    if (columnName === "name") {
      const sorted =
        order && order === "ASC"
          ? dataMarkets.sort((a, b) =>
            a["name"].toLowerCase() > b["name"].toLowerCase() ? 1 : -1
          )
          : dataMarkets.sort((a, b) =>
            a["name"].toLowerCase() < b["name"].toLowerCase() ? 1 : -1
          );
      setDataMarkets(sorted);
    }
    else if (columnName === "price") {
      const sorted =
        order && order === "ASC"
          ? dataMarkets.sort((a, b) =>
            a["currentMarketPrice"] > b["currentMarketPrice"] ? 1 : -1
          )
          : dataMarkets.sort((a, b) =>
            a["currentMarketPrice"] < b["currentMarketPrice"] ? 1 : -1
          );
      setDataMarkets(sorted);
    }
    else if (columnName === "dayChange") {
      const sorted =
        order && order === "ASC"
          ? dataMarkets.sort((a, b) =>
            a["dayChange"] > b["dayChange"] ? 1 : -1
          )
          : dataMarkets.sort((a, b) =>
            a["dayChange"] < b["dayChange"] ? 1 : -1
          );
      setDataMarkets(sorted);
    }
    const sortingType = order && order === "ASC" ? "DSC" : "ASC";
    setOrder(sortingType);
    setActiveColumn({ key: columnName, value: true });
  };

  return (
    <div className={`${tradingType?.toLowerCase() === "cross" ? styles.crossCurrency : styles.currency}`}>
      <div className={styles.stickyHeaderNav}>
        <div className={styles.nav}>
          {allMarketsData?.map((x, index) => (
            <button
              className={cn(styles.link, {
                [styles.active]: index === activeIndex,
              })}
              onClick={() => { setActiveIndex(index); setCurrencyId(x.currencyId) }}
              key={index}
            >
              {x.currency}
            </button>
          ))}
        </div>
        <form className={styles.form} action="" onSubmit={(e) => handleSubmit(e)}>
          <input
            className={styles.input}
            type="text"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            name="search"
            placeholder="Search by pair"
            required
          />
          <button className={styles.result}>
            <Icon name="search" size="20" />
          </button>
        </form>
        <div className={styles.table}>
          <div className={styles.customRow}>
            <div className={styles.customCol}>
              <div className={cn("sorting", { [styles.activeColumn]: activeColumn.key === "name" })}
                onClick={() => { handleSort("name") }}>
                Pair
              </div>
            </div>
            <div className={styles.customCol}>
              <div className={cn("sorting", { [styles.activeColumn]: activeColumn.key === "price" })}
                onClick={() => { handleSort("price") }}>
                Price
              </div>
            </div>
            <div className={styles.customCol}>
              <div className={cn("sorting", { [styles.activeColumn]: activeColumn.key === "dayChange" })}
                onClick={() => { handleSort("dayChange") }}>
                Change
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.table}>
        {/* <div className={styles.col}>
          <div >Pair</div>
        </div>
        <div className={styles.col}>
          <div >Price</div>
        </div>
        <div className={styles.col}>
          <div>Change</div>
        </div> */}
        {
          // marketLoading ? <ExchangeSkeleton rowCount={22} colCount={3} />
          //   :
          // allMarketsData.map((x, index) => {
          //   return x.markets?.sort(compare)?.map((x, count) => {
          dataMarkets?.map((x, index) => {
            return (search !== "" && x?.slug?.replace("-", "/")?.toLowerCase()?.indexOf(search?.toLowerCase()) === -1) ? null :
              <div className={styles.row} key={index}>
                <div className={styles.col}>
                  <div className={styles.line}>
                    {tradingType?.toLowerCase() === "spot" ?
                      <Link to={`/exchange/${x.slug}?type=spot`} className={styles.market}>
                        <div className={styles.info}>
                          {x?.slug?.replace("-", "/")?.toUpperCase()}
                        </div>
                      </Link>
                      :
                      <Link to={`/exchange/${x.slug}?type=cross`} className={styles.market}>
                        <div className={styles.info}>
                          {x?.slug?.replace("-", "/")?.toUpperCase()}{(x?.isMarginTrading === parseInt(process.env.REACT_APP_MARGIN_TRADING_ENABLE) && tradingType === "cross") && <span className={styles.marginText}>{x?.maxLeverageTrading}x</span>}
                        </div>
                      </Link>
                    }
                    {/* <Link to={`/exchange/${x.slug}`} className={styles.market}>
                      <div className={styles.info}>
                        {x.slug.replace("-", "/").toUpperCase()}{(x?.isMarginTrading === parseInt(process.env.REACT_APP_MARGIN_TRADING_ENABLE) && tradingType === "cross") && <span>{x?.maxLeverageTrading}x</span>}
                      </div>
                    </Link> */}
                  </div>
                </div>
                <div className={styles.col}>  {getDigitsAfterDecimal(x.currentMarketPrice, x.priceDecimalPrecision)}</div>
                <div className={styles.col}>
                  {parseFloat(x.dayChange) < 0 ? (
                    <div className={styles.negative}>{parseFloat(x.dayChange).toFixed(2)}<small>%</small></div>
                  ) : (
                    <div className={styles.positive}><small>+</small>{parseFloat(x.dayChange).toFixed(2)}<small>%</small></div>
                  )
                  }
                </div>
              </div>
          })
        }
      </div>
    </div>
  );
};

export default Currency;
