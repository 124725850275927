import React, { useState } from "react";
import styles from "./Funds.module.sass";
import Item from "./Item";
import Icon from "../../../components/Icon";
import NoDataFound from "../../../components/NoDataFound";
import cn from "classnames";
import OrderSkeleton from "../../../components/Skeleton/OrderSkeleton";
import Loader from "../../../components/Loader";

const Funds = ({ allOrders, handleLoadMore, children, handleReload, pageno, loading, btnLoading }) => {
  const [search, setSearch] = useState('');
  return (
    <div className={styles.wrap}>
      <div className={styles.line}>
        <form className={styles.form} >
          <input
            className={styles.input}
            type="text"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            name="search"
            placeholder="Search Order By Market"
            required
          />
          <button onClick={(e) => { e.preventDefault() }} className={styles.result}>
            <Icon name="search" size="20" />
          </button>
        </form>
      </div>
      <div className={styles.list}>
        <div className={styles.row}>
          <div className={styles.col}>ID</div>
          <div className={styles.col}>Market</div>
          <div className={styles.col}>Type</div>
          <div className={styles.col}>Rate</div>
          <div className={styles.col}>Amount</div>
          <div className={styles.col}>Value</div>
          <div className={styles.col}>Fee</div>
          <div className={styles.col}>Status</div>
          <div className={styles.col}>Order Type</div>
          <div className={styles.col}>Action</div>
        </div>
        {loading ? <OrderSkeleton rowCount={10} colCount={9} />
          :
          allOrders?.data?.data?.length > 0 ?
            <>
              {allOrders?.data?.data?.map((x, index) => {
                return search !== "" &&
                  x.slug.toLowerCase().indexOf(search.toLowerCase()) ===
                  -1 ?
                  null :
                  <Item
                    className={styles.item}
                    item={x}
                    key={index}
                    children={children}
                    handleReload={handleReload}
                  />
              })}
              {
                allOrders?.data?.data?.length === (10 * pageno) && <div className={styles.customButton}>
                  <button className={cn("button-stroke button-small", styles.button)} onClick={() => handleLoadMore()} >
                    {btnLoading && (<Loader className={styles.loader} />)}
                    <span>Load more</span>
                  </button>
                </div>
              }
            </>
            :
            <div className={styles.btns}>
              <NoDataFound className={styles.customButton} />
            </div>
        }
      </div>
    </div>
  );
};

export default Funds;
