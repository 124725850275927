import React from "react";
import cn from "classnames";
import styles from "./Article.module.sass";

import PDF from "./Documents/INT_Bitdenex_Term_of_Service_V.01.03.2023.pdf"

const Article = () => {
  return (
    <div className={cn("section", styles.main)}>
      <div className={cn("container", styles.container)}>
        <div className={styles.content}>
          <h3>Terms of Service</h3>
          <p>
            <a href={PDF} target="_blank" rel="noreferrer">Complete Terms of Service can be downloaded and viewed here</a>
          </p>
          <p>
            The following is a summary of some of our important restrictions.
          </p>
          <p>
            Bitdenex International Exchange is operated by Coindenex LLC., a company incorporated under the laws of St. Vincent & The Grenadines (further referred as “Bitdenex”).
          </p>
          <p>
            Bitdenex does not onboard or provide services to corporate accounts of entities located in, established in, or a resident of the United States of America, Cuba, Crimea and Sevastopol, Iran, Afghanistan, Syria, or North Korea. Bitdenex also does not onboard any users from Ontario, or non- professional investors from Hong Kong. Our primary jurisdiction is Europe, the European Union.
          </p>
          <p>
            Bitdenex does not onboard or provide services to personal accounts of current residents of the United States of America, Cuba, Crimea and Sevastopol, Iran, Afghanistan, Syria, North Korea, or future embargoed countries referring to the international applicable embargo country list. Bitdenex also does not onboard any users from Ontario, or non-professional investors from Hong Kong.
          </p>
          <p>
            There may be partial restrictions in other jurisdictions, potentially including Hong Kong, Thailand, Malaysia, and Canada.
          </p>
          <p>
            Please feel free to reach out to us with any further questions, and feel free to upload any supplementary documents at support@bitdenex.com
          </p>
          <p>
            If you deposit funds to the wrong address, Bitdenex is not responsible for any losses. If the funds are recoverable–e.g. sending an ERC20 or TRC20 token to your ETH address or OMNI USDT to your BTC address–we can try to recover the funds. Doing so is not guaranteed and might take up to a week. We also charge a $500 fee for recovery. Similarly, sending ETH or TRX or other coins or tokens via a smart contract to Bitdenex may not be automatically credited, and may take time to recover.
          </p>
          <p>
            There are no refunds permitted for Credit Card purchases of cryptocurrencies on Bitdenex, if applicable. Bitdenex does not permit self trades executed in order to manipulate markets, reported statistics, or cause liquidations. Doing so is a violation of the Bitdenex terms of service and may result in account closure.
          </p>
          <p>
            To ensure account security, please set up Two-Factor Authentication (2FA) for your Bitdenex account immediately. Our platform will not be responsible for any losses for accounts whose credentials are breached.
          </p>
          <p>
            If you are registering to use the Services as an individual, you must be at least 18 years of age, and you must not have been previously been suspended or removed from the Exchange or any other service or product offered by Bitdenex or its affiliate entities, to enter into this Agreement.
          </p>
          <p>
            If Bitdenex has evidence that an account is violating the terms of service, Bitdenex reserves the right to shut down the account and, except in cases of suspected violations of our anti money laundering (AML) policy or other similar concerns about the source of wealth or malicious market manipulation, return funds to the user after consulting the applicable local legal supervisory authority.
          </p>
          <p>
            Note that, among other things, Bitdenex does not guarantee the right to trade. While Bitdenex will take all reasonable methods to ensure that accounts do not receive any unwanted fills outside of our standard liquidation procedures, risk engine, and the terms of service, Bitdenex does not as a matter of principle have any obligation to honour people’s desires for trades to have occurred that didn’t.
          </p>
          <p>
            Note that, while Google and Bitdenex are translated into multiple languages, in cases of inconsistent statements the English language versions will take precedence.
          </p>


          <h3>NOTE: Give-aways and Bonus:</h3>
          <p>
            1. Bonus and Give-aways given by Bitdenex cannot be withdrawn, you are not allowed to withdraw your received “Give-aways” or “Bonuses”, unless you make a first deposit and make a trade.
          </p>
          <p>
            2. Bonus and Give-aways are forfeited upon any asset withdrawal.
          </p>
          <figure>
            <img src="/images/content/terms/article.jpg" alt="Content" />
          </figure>
          <h3>View Complete Terms of Service</h3>
          <p>
            <a href={PDF} target="_blank" rel="noreferrer">Complete Terms of Service can be downloaded and viewed here</a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Article;
