import React from "react";
import cn from "classnames";
import styles from "./PaymentDetails.module.sass";
import Icon from "../../../components/Icon";
import { addNotification } from "../../../components/Notification";

const PaymentDetails = ({ calculateTransactionFee, price, calculatePriceAfterFee, relativeFee, absoluteFee,
  depositFeeData, sepaData, formData, linkData, depositFiatLoading, getUnconfirmedTransactionHandler }) => {

  return (
    <div className={styles.item}>
      <div className={styles.title}>Payment details</div>
      <div className={styles.secondText}>Note : This amount will reflect in your USDT wallet.</div>
      {(!formData.bankText.toLowerCase().includes("wire")) && <div className={styles.info}>Your are depositing {formData.amount} EUR</div>}
      {(!formData.bankText.toLowerCase().includes("wire")) &&
        <>
          {/* <div className={styles.customInfo}>Amount Details</div> */}
          <div className={styles.list}>
            <div className={styles.line}>
              <div className={styles.subtitle}>Entered Amount</div>
              <div className={styles.details}>
                <div className={styles.content}>{price} <span className={styles.currencyText}>EUR</span></div>
              </div>
            </div>
            <div className={styles.line}>
              <div className={styles.subtitle}>You Receive</div>
              <div className={styles.details}>
                <div className={styles.content}>{isNaN(calculatePriceAfterFee()) ? "0.00" : calculatePriceAfterFee()} <span className={styles.currencyText}>EUR</span></div>
                {/* <div className={styles.content}>{calculatePriceAfterFee()} <span className={styles.currencyText}>EUR.</span></div> */}
              </div>
            </div>
            <div className={styles.line}>
              <div className={styles.subtitle}>Fees(Relative + Absolute)</div>
              <div className={styles.details}>
                {/* <div className={styles.content}>{price !== "0.00" ? ((parseFloat(relativeFee) + parseFloat(absoluteFee)) < 0 ? "0" : parseFloat(relativeFee) + parseFloat(absoluteFee)) : "0"} <span className={styles.currencyText}>EUR.</span></div> */}
                <div className={styles.content}>{isNaN(calculateTransactionFee()) ? "0.00" : calculateTransactionFee()} <span className={styles.currencyText}>EUR</span></div>
              </div>
            </div>
          </div>
        </>
      }
      {formData.bankText && formData.bankText.toLowerCase().includes("wire") && (
        <>
          <div className={styles.info}>Bank account</div>
          <div className={styles.list}>
            <div className={styles.line}>
              <div className={styles.subtitle}>Account name</div>
              <div className={styles.details}>
                <div className={styles.content}>{sepaData?.name}</div>
                <button className={styles.copy} onClick={(e) => {
                  e.preventDefault();
                  navigator.clipboard.writeText(sepaData?.name).then(() => {
                    addNotification({ title: 'Success', message: 'Text copied successfully', type: 'success' })
                  })
                }}>
                  <Icon name="copy" size="24" />
                </button>
              </div>
            </div>
            <div className={styles.line}>
              <div className={styles.subtitle}>Account number / IBAN</div>
              <div className={styles.details}>
                <div className={styles.content}>{sepaData?.account_number_iban}</div>
                <button className={styles.copy} onClick={(e) => {
                  e.preventDefault();
                  navigator.clipboard.writeText(sepaData?.account_number_iban).then(() => {
                    addNotification({ title: 'Success', message: 'Text copied successfully', type: 'success' })
                  })
                }}>
                  <Icon name="copy" size="24" />
                </button>
              </div>
            </div>
            <div className={styles.line}>
              <div className={styles.subtitle}>Address</div>
              <div className={styles.details}>
                <div className={styles.content}>{sepaData?.address}</div>
                <button className={styles.copy} onClick={(e) => {
                  e.preventDefault();
                  navigator.clipboard.writeText(sepaData?.address).then(() => {
                    addNotification({ title: 'Success', message: 'Text copied successfully', type: 'success' })
                  })
                }}>
                  <Icon name="copy" size="24" />
                </button>
              </div>
            </div>
            <div className={styles.line}>
              <div className={styles.subtitle}>BIC/SWIFT Code</div>
              <div className={styles.details}>
                <div className={styles.content}>{sepaData?.bic_swift_code}</div>
                <button className={styles.copy} onClick={(e) => {
                  e.preventDefault();
                  navigator.clipboard.writeText(sepaData?.bic_swift_code).then(() => {
                    addNotification({ title: 'Success', message: 'Text copied successfully', type: 'success' })
                  })
                }}>
                  <Icon name="copy" size="24" />
                </button>
              </div>
            </div>
            <div className={styles.line}>
              <div className={styles.subtitle}>Bank Address</div>
              <div className={styles.details}>
                <div className={styles.content}>{sepaData?.bank_address}</div>
                <button className={styles.copy} onClick={(e) => {
                  e.preventDefault();
                  navigator.clipboard.writeText(sepaData?.bank_address).then(() => {
                    addNotification({ title: 'Success', message: 'Text copied successfully', type: 'success' })
                  })
                }}>
                  <Icon name="copy" size="24" />
                </button>
              </div>
            </div>
          </div>

          <div className={styles.info}>Reference code</div>
          <div className={styles.text}>
            You MUST include the Reference Code in your deposit in order to credit
            your account! <br></br>Reference Code:
          </div>
          <div className={styles.code}>{formData.reference_code}</div>
          <div className={styles.text}>
            Note: This is Wire transfer, means you need to manually transfer your funds to the above mentioned Bitdenex Bank account citing the "Reference Code".
          </div>
        </>
      )}
      <div className={styles.btns}>
        <button className={cn("button", styles.button)}
          disabled={depositFiatLoading}
          onClick={
            () => {
              // submitHandler();
              getUnconfirmedTransactionHandler();
            }
          }>
          {formData.bankText && formData.bankText.toLowerCase().includes("wire") ? "Confirm Wire transfer" : "Continue"}
        </button>
      </div>
    </div>
  );
};

export default PaymentDetails;
