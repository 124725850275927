import React, { useEffect, useState } from "react";
import styles from "./ProfileInfo.module.sass";
import Profile from "../../components/Profile";
import cn from "classnames";
import LoaderScreen from "../../components/LoaderScreen";
import requestHandler from "../../actions/httpClient";
import Referrals from "./Referrals";
import { useDispatch, useSelector } from "react-redux";
import { userStatusCreator } from "../../actions/getUserStatus";
import { getDigitsAfterDecimal } from "../../components/helper";

const breadcrumbs = [
  {
    title: "My Referrals",
    url: "/",
  },
  {
    title: "My Referrals",
  },
];

const ProfileInfo = () => {
  const [loading, setLoading] = useState();
  const [referralData, setReferralData] = useState([]);
  const { userStatus } = useSelector((state) => { return state.getUserStatus });
  const dispatch = useDispatch();

  useEffect(() => {

    if (userStatus && Object.keys(userStatus).length === 0) {
      dispatch(userStatusCreator());
    }

    const userStatusVerification = async () => {
      try {
        let data = {
          signature: localStorage.getItem("signature"),
        };
        const payload = await requestHandler('getCustomerVerifictionStatus', 'post', data, 'jwt_token')
        getReferralList(payload?.data?.data?.id);
      }
      catch (error) {
      }
    };
    userStatusVerification();
  }, []);

  const getReferralList = async (id) => {
    setLoading(true);
    try {
      let data = {
        signature: localStorage.getItem("signature"),
        userId: id,
      };
      const getReferralPayload = await requestHandler("getRefferal", "post", data, "jwt_token");
      setReferralData(getReferralPayload?.data?.data);
      setLoading(false);
    }
    catch (e) {
      setLoading(false);
    };
  };

  function formatNumberWithCommas(number) {
    return number.toLocaleString('en-US');
  };

  return (
    <Profile title="My Referrals" breadcrumbs={breadcrumbs}>
      {loading && <LoaderScreen />}
      <div className={styles.settings}>
        <div className={styles.settings}>
          <div className={styles.customSubtitle}>Level Details</div>
          <div className={styles.container}>
            <div className={styles.couponCard}>
              <div className={styles.couponHeader}>
                <h3>
                  Level : {(userStatus?.user_level === undefined || userStatus?.user_level === null) ? "---" : userStatus?.user_level}
                </h3>
                <h4>
                  Monthly volume(Fiat) : {(userStatus?.current_month_volume === undefined || userStatus?.current_month_volume === null) ? "---" : getDigitsAfterDecimal(userStatus?.current_month_volume, 2)}
                </h4>
                {userStatus?.user_level === 3 ?
                  ""
                  :
                  <h4>
                    Next Level : {(userStatus?.nextLevelDetails?.name === undefined || userStatus?.nextLevelDetails?.name === null) ? "---" : userStatus?.nextLevelDetails?.name}
                  </h4>
                }
              </div>
              <div className={styles.couponFooter}>
                {/* <span>Amount to achieve next level is {(userStatus?.current_month_volume === undefined || userStatus?.current_month_volume === null) ? "---" : ((userStatus?.nextLevelDetails?.min_volume - userStatus?.current_month_volume) < 0 ? 0 : getDigitsAfterDecimal((userStatus?.nextLevelDetails?.min_volume - userStatus?.current_month_volume), 2))} Fiat
                  and atleast {userStatus?.user_level === null || userStatus?.user_level === undefined ? userStatus?.nextLevelDetails?.max_invites : userStatus?.nextLevelDetails?.min_invites - referralData?.length} referrals.
                </span> */}
                {userStatus?.user_level === 3 ?
                  ""
                  :
                  <span>
                    To achieve next level your referrals need to trade atleast for {formatNumberWithCommas(userStatus?.nextLevelDetails?.min_volume)} fiat and you need to refer atleast {userStatus?.nextLevelDetails?.min_invites} persons.
                  </span>
                }
              </div>
            </div>
          </div>
          <div className={styles.box} >
            <div className={styles.subtitle}>My Referrals</div>
            <div className={styles.table}>
              <div className={styles.row}>
                <div className={styles.col}>#</div>
                <div className={styles.col}>Id</div>
                <div className={styles.col}>Status</div>
              </div>
              {referralData?.map((x, index) => (
                <Referrals
                  className={styles.item}
                  item={x}
                  key={index}
                  index={index}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </Profile>
  );
};

export default ProfileInfo;
