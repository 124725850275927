import React, { useRef, useState } from "react";
import cn from "classnames";
import styles from "./EnterAmount.module.sass";
import Icon from "../../../components/Icon";
import { addNotification } from "../../../components/Notification";
import SimpleReactValidator from "simple-react-validator";
import { getDigitsAfterDecimal } from "../../../components/helper";

const priceVariants = ["50.00 USDT", "100.00 USDT", "200.00 USDT", "500.00 USDT"];

const EnterAmount = ({ goNext, goBack, value, currencyBalance, decimalPrecision }) => {
  const [price, setPrice] = useState("");
  const simpleValidator = useRef(new SimpleReactValidator());
  const [forceUpdate, setForceUpdate] = useState();

  return (
    <form className={styles.item} action="" >
      <div className={styles.control}>
        <button className={styles.back} onClick={goBack}>
          <Icon name="arrow-prev" size="14" />
          Enter amount
        </button>
        <div className={styles.money}>
          Buying {value.name}
          <img src={value.icon} alt="Coin" />
        </div>
      </div>
      <div className={styles.payment}>
        <div className={cn("h4", styles.sign)}>{process.env.REACT_APP_USDT_SIGN}</div>
        <div className={styles.field}>
          <div className={styles.value}>{price}</div>
          <input
            className={styles.input}
            name="price"
            value={price}
            onChange={(e) => setPrice(e.target.value)}
            placeholder="0"
          />
        </div>
      </div>
      <div className={styles.price}>
        <span>You have </span>{getDigitsAfterDecimal(currencyBalance, decimalPrecision)}<span>USDT</span>.
        <span>{simpleValidator.current.message("price", price, "required")}</span>
      </div>
      {/* <div className={styles.variants}>
        {priceVariants.map((x, index) => (
          <button
            className={cn("button-stroke button-small", styles.button)}
            type="button"
            key={index}
            onClick={() => setPrice(x.replace("USDT", ""))}
          >
            {x}
          </button>
        ))}
      </div> */}
      <div className={styles.btns}>
        <button className={cn("button", styles.button)} onClick={(e) => {
          e.preventDefault();
          if (simpleValidator.current.allValid()) {
            if (parseFloat(price) > 0) {
              if (parseFloat(price) > parseFloat(currencyBalance)) {
                addNotification({
                  title: 'Error',
                  message: 'Insufficient balance',
                  type: 'danger'
                })
              } else {
                goNext(price)
              }
            }
            else {
              addNotification({
                title: 'Error',
                message: "You can't add negative values and 0 in price field.",
                type: 'danger'
              })
            }
          }
          else {
            simpleValidator.current.showMessages();
            setForceUpdate(forceUpdate + 1);
          }
        }
        }>
          Continue
        </button>
      </div>
    </form>
  );
};

export default EnterAmount;
