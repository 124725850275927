import React, { useState } from "react";
import styles from "./Table.module.sass";
import BankSkeleton from "../../../components/Skeleton/BankSkeleton";

const Table = ({ bankList, loading }) => {
  return (
    <div className={styles.inner}>
      <div className={styles.table}>
        <div className={styles.row}>
          <div className={styles.stage}>Added Accounts</div>
        </div>
        <div className={styles.row}>
          <div className={styles.col}>
            <div className={styles.stage}>Payment Mode</div>
          </div>
          <div className={styles.col}>
            <div className={styles.stage}>Account Number</div>
          </div>
          {/* <div className={styles.col}>
            <div className={styles.stage}>Bank Name</div>
          </div> */}
          <div className={styles.col}>
            <div className={styles.stage}>Status</div>
          </div>
        </div>
        {loading ? <BankSkeleton count={2} height={10} />
          :
          bankList?.map((x, index) =>
            <div className={styles.row} key={index}>
              <div className={styles.col}>{x?.payment_mode?.toLowerCase() === "sepa" && "Wire Transfer"}</div>
              <div className={styles.col}>{x?.account_number}</div>
              {/* <div className={styles.col}>{x?.bank_name == null ? "---" : x?.bank_name}</div> */}
              <div className={styles.col}>
                {x?.status == parseInt(process.env.REACT_APP_BANK_VARIFICATION_PENDING) && (
                  <div className={styles.info}>Pending</div>
                )}
                {x?.status == parseInt(process.env.REACT_APP_BANK_VARIFICATION_PROCESS) && (
                  <div className={styles.info}>Inprocess</div>
                )}
                {x?.status == parseInt(process.env.REACT_APP_BANK_VARIFICATION_COMPLETE) && (
                  <div className={styles.info}>Completed</div>
                )}
                {x?.status == parseInt(process.env.REACT_APP_BANK_VARIFICATION_FAILURE) && (
                  <div className={styles.info}>Failure</div>
                )}
                {x?.status == parseInt(process.env.REACT_APP_BANK_VARIFICATION_REJECT) && (
                  <div className={styles.info}>Rejected</div>
                )}
              </div>
            </div>
          )}
      </div>
    </div>
  );
};

export default Table;