import React, { useState } from 'react'
import styles from "./Leverage.module.sass";
import cn from "classnames";

const LeverageTrading = ({
    setVisibleModal,
    currentMarketDetails,
    setLeverage,
}) => {
    const buttonValues = [
        { text: '3x', condition: currentMarketDetails[0]?.maxLeverageTrading >= 3 },
        { text: '5x', condition: currentMarketDetails[0]?.maxLeverageTrading >= 5 },
        { text: '10x', condition: currentMarketDetails[0]?.maxLeverageTrading > 5 }
    ];
    const [selectedLeverage, setSelectedLeverage] = useState(null);
    const isConfirmButtonEnabled = selectedLeverage !== null;

    const handleButtonClick = (text) => {
        setSelectedLeverage(text);
    };

    return (
        <div className={styles.successfully}>
            <div className={styles.info}>
                Cross Margin Max Leverage
            </div>
            <div className={styles.buttoncontainer}>
                {buttonValues.map((button, index) => (
                    button?.condition ? (
                        <button
                            key={button.text}
                            className={cn(styles.buttonClass, {
                                [styles.active]: selectedLeverage === button.text
                            })}
                            onClick={() => handleButtonClick(button.text)}
                        >
                            {button.text}
                        </button>
                    ) : null
                ))}
            </div>
            <div className={styles.btns}>
                <button
                    className={cn("button-small", styles.button, {
                        [styles.selected]: isConfirmButtonEnabled,
                        [styles.disabled]: !isConfirmButtonEnabled
                    })}
                    onClick={() => {
                        if (isConfirmButtonEnabled) {
                            setVisibleModal(false);
                            setLeverage(selectedLeverage);
                        }
                    }}
                    disabled={!isConfirmButtonEnabled}
                >
                    Confirm
                </button>
            </div>
        </div>
    )
}

export default LeverageTrading;
