import React, { useState } from "react";
import cn from "classnames";
import styles from "./Confirm.module.sass";
import Icon from "../../../components/Icon";
import Modal from "../../../components/Modal";
import Successfully from "./Successfully";
import requestHandler from "../../../actions/httpClient";
import { userStatusCreator } from "../../../actions/getUserStatus";
import { useDispatch } from "react-redux";
import { addNotification } from "../../../components/Notification";
import { useNavigate } from "react-router";
import LoaderScreen from "../../../components/LoaderScreen";

const Confirm = ({ goFinish, goBack, value, coinBalance, price }) => {
  const [visibleSuccessfully, setVisibleSuccessfully] = useState(false);
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const options = [
    {
      title: "Sell",
      content: [price],
      color: "#9757D7",
      icon: "wallet",
    },
    {
      title: "Method",
      content: "Instant",
      color: "#58BD7D",
      icon: "wallet",
    },

  ];
  const parameters = [
    {
      title: "You will Sell",
      content: [price],
    },
  ];

  const handleSell = async () => {
    try {
      setLoading(true)
      const signature = localStorage.getItem("signature")
      let data = {
        'marketId': value.id,
        'orderType': process.env.REACT_APP_SELL_MARKET_ORDER,
        'fiat_price': null,
        'fiat_spend': null,
        'crypto_spend': price,
        'signature': signature,
      }

      const payload = await requestHandler('new_order', 'post', data, 'jwt_token')
      setLoading(false)
      if (payload.status === 200) {
        dispatch(userStatusCreator());
        addNotification({
          title: 'Success',
          message: payload.data.message[0].msg,
          type: 'success'
        });
        navigate("/open-orders-list");
        // setVisibleSuccessfully(true)
      }

    } catch (error) {
      setLoading(false)
    }
  }


  return (
    <>
      <div className={styles.item}>
        <div className={styles.control}>
          <button className={styles.back} onClick={goBack}>
            <Icon name="arrow-prev" size="14" />
            Confirm
          </button>
          <div className={styles.money}>
            Selling {value.name}
            <img src={value.icon} alt="Coin" />
          </div>
        </div>
        <div className={styles.options}>
          {options.map((x, index) => (
            <div className={styles.option} key={index}>
              <div className={styles.icon} style={{ backgroundColor: x.color }}>
                <Icon name={x.icon} size="20" />
              </div>
              <div className={styles.details}>
                <div className={styles.category}>{x.title}</div>
                <div className={styles.content}>{x.content}</div>
              </div>
            </div>
          ))}
        </div>
        <div className={styles.info}>
          You are about to sell {price} {value.name}.
        </div>
        <div className={styles.table}>
          {parameters.map((x, index) => (
            <div className={styles.row} key={index}>
              <div className={styles.cell}>{x.title}</div>
              <div className={styles.cell}>{x.content}</div>
            </div>
          ))}
        </div>
        <div className={styles.category}>available balance {coinBalance} {' '}{value.symbol}.</div>
        <div className={styles.btns}>
          <button
            className={cn("button-stroke", styles.button)}
            onClick={goFinish}
          >
            Cancel
          </button>
          <button
            className={cn("button", styles.button)}
            onClick={() => handleSell()}
          >
            I understand, continue
          </button>
        </div>
      </div>
      <Modal
        visible={visibleSuccessfully}
        onClose={() => setVisibleSuccessfully(false)}
      >
        <Successfully value={value} />
      </Modal>
      {loading && <LoaderScreen />}
    </>
  );
};

export default Confirm;
