import React from 'react';
import styles from './HeaderTextComponent.module.sass';

const HeaderTextComponent = ({ visible, onClose }) => {

    return (
        <>
            {visible &&
                <div className={styles.notification}>
                    <span>Please note that you are entering the website of Bitdenex International, which is operated by Coindenex LLC. Bitdenex International is not authorized in the European Union to provide cryptocurrency  services, investment services nor any trading services. By using this website, you agree to waive the protection afforded under European Union rules and acknowledge that you wish to continue to open and use an account with us at your own initiative and risk.
                    </span>
                    {/* <button className={styles.closeButton} onClick={() => { onClose() }}>Close</button> */}
                </div>
            }
        </>
    );
};
export default HeaderTextComponent;
