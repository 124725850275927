import React, { useState } from "react";
import cn from "classnames";
import styles from "./Item.module.sass";
import { toLocaleStringDateFormat } from "../../../../components/helper";

const Item = ({ item, children }) => {
  const [visible, setVisible] = useState(false);

  return (
    <>
      <div className={cn(styles.item, { [styles.active]: visible })}>
        <div className={styles.row}>
          <div className={styles.col}>
            <div className={styles.currency}>
              <div className={styles.details}>
                <div className={styles.info}>{item?.source?.type.toLowerCase() === "sepa" && "Wire Transfer"}</div>
              </div>
            </div>
          </div>
          {/* <div className={styles.col}>
            <div className={styles.info}>{item?.source?.bic}</div>
          </div> */}
          <div className={styles.col}>
            <div className={styles.info}>{item?.amount}</div>
          </div>
          <div className={styles.col}>
            <div className={styles.info}>{item?.reference}</div>
          </div>
          <div className={styles.col}>
            <div className={cn("category-blue", styles.details)}>{item?.payment_status}</div>
          </div>
          <div className={styles.col}>
            <div className={styles.info}>{toLocaleStringDateFormat(item?.createdAt)}</div>
          </div>
        </div>
        <div className={styles.btns}>{children}</div>
      </div>
    </>
  );
};

export default Item;
