import React, { useState } from "react";
import cn from "classnames";
import styles from "./Trend.module.sass";
import { Link } from "react-router-dom";
import Dropdown from "../../../components/Dropdown";
import { getDigitsAfterDecimal, getPostiveNegativeNum } from "../../../components/helper";
import TradeSkeleton from "../../../components/Skeleton/TradeSkeleton";

const navigation = [
  "All",
  "DeFi",
  "Innovation",
  "POS",
  "NFT",
  "POW",
  "Storage",
];

const data = [
  {
    name: "1",
    price: 1000,
  },
  {
    name: "2",
    price: 2300,
  },
  {
    name: "3",
    price: 2000,
  },
  {
    name: "4",
    price: 2780,
  },
  {
    name: "5",
    price: 1890,
  },
  {
    name: "6",
    price: 2390,
  },
  {
    name: "7",
    price: 2490,
  },
  {
    name: "8",
    price: 3000,
  },
  {
    name: "9",
    price: 2500,
  },
  {
    name: "10",
    price: 2000,
  },
  {
    name: "11",
    price: 2780,
  },
  {
    name: "12",
    price: 1890,
  },
  {
    name: "13",
    price: 2390,
  },
  {
    name: "14",
    price: 1490,
  },
];

const Trend = ({ marketsData, loading }) => {

  const [sorting, setSorting] = useState(navigation[0]);
  return (
    <div className={cn("section", styles.section)}>
      <div className={cn("container", styles.container)}>
        <div className={styles.line}>
          <h2 className={cn("h3", styles.title)}>Popular cryptocurrencies</h2>
          <Link className={cn("button-stroke", styles.button)} to="/market">
            View more
          </Link>
        </div>
        <div className={styles.dropdown}>
          <Dropdown
            className={styles.dropdown}
            value={sorting}
            setValue={setSorting}
            options={navigation}
          />
        </div>
        <div className={styles.table}>

          <div className={styles.row}>
            <div className={styles.col}>#</div>
            <div className={styles.col}>Name</div>
            <div className={styles.col}>Price</div>
            <div className={styles.col}>24h change</div>
            <div className={styles.col}>7d %</div>
            <div className={styles.col}>Trade</div>
          </div>
          {loading ? <TradeSkeleton rowCount={9} colCount={6} />
            :
            marketsData
              ?.slice(0, 9)
              ?.map((x, index) => (
                <Link className={styles.row} to={localStorage.getItem("signature") ? `/exchange/${x.slug}` : process.env.REACT_APP_GLOBAL_URL} key={index}>
                  <div className={styles.col}>{index + 1}</div>
                  <div className={styles.col}>
                    <div className={styles.item}>
                      <div className={styles.icon}>
                        <img src={x.icon} alt="Currency" />
                      </div>
                      <div className={styles.details}>
                        <span className={styles.subtitle}>{x.coin_name}</span>
                        <span className={styles.currency}>{x.symbol}</span>
                      </div>
                    </div>
                  </div>
                  <div className={styles.col}>{getDigitsAfterDecimal(x.currentMarketPrice, x.priceDecimalPrecision)}</div>
                  <div className={styles.col}>
                    {getPostiveNegativeNum(x.dayChange) ?
                      <div className={styles.positive}>{getDigitsAfterDecimal(x.dayChange, 2)}%</div>
                      :
                      <div className={styles.negative}>{getDigitsAfterDecimal(x.dayChange, 2)}%</div>
                    }
                  </div>
                  <div className={styles.col}>
                    <div className={styles.col}>
                      {getPostiveNegativeNum(x.weekChange) ?
                        <div className={styles.positive}>{getDigitsAfterDecimal(x.weekChange, 2)}%</div>
                        :
                        <div className={styles.negative}>{getDigitsAfterDecimal(x.weekChange, 2)}%</div>
                      }
                    </div>
                  </div>
                  <div className={styles.col}>
                    <div className={styles.col}>
                      <button className={cn("button-stroke", styles.button)}>
                        Trade Now
                      </button>
                    </div>
                  </div>
                </Link>
              ))}
        </div>
      </div>
    </div>
  );
};

export default Trend;
