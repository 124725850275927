import React, { useEffect, useState } from "react";
import cn from "classnames";
import styles from "./Item.module.sass";
import Modal from "../../../../components/Modal";
import Successfully from "./Successfully";
import { useDispatch, useSelector } from "react-redux";
import { toLocaleStringDateFormat, getDigitsAfterDecimal } from "../../../../components/helper";
import { addNotification } from "../../../../components/Notification";
import requestHandler from "../../../../actions/httpClient";
import { userStatusCreator } from "../../../../actions/getUserStatus";

const Item = ({ item, children, handleReload }) => {

  const [visible, setVisible] = useState(false);
  const [visibleSuccessfully, setVisibleSuccessfully] = useState(false);
  const dispatch = useDispatch();

  const cancelOpenOrder = async (orderId) => {
    try {
      const data = {
        signature: localStorage.getItem("signature"),
        orderId: orderId
      }
      const response = await requestHandler('cancelOrder', 'post', data, 'jwt_token')
      if (response?.status == 200) {
        addNotification({
          title: 'Success',
          message: 'Order cancelled successfully',
          type: 'success'
        })
        setVisibleSuccessfully(false)
        handleReload(response)
        dispatch(userStatusCreator());
      }
    }
    catch (error) {
      if (error) {
        addNotification({
          title: 'Error',
          message: 'Something went wrong',
          type: 'danger'
        })
        setVisibleSuccessfully(false)
      }
    }
  }

  return (
    <>
      <div className={cn(styles.item, { [styles.active]: visible })}>
        <div className={styles.row} onClick={() => setVisible(!visible)} >
          <div className={styles.col}>
            <div className={styles.info}><small>{item?.orderId}</small></div>
          </div>
          <div className={styles.col}>{item?.slug?.toUpperCase()}
          </div>
          <div className={styles.col}>
            {(item?.orderType === parseInt(process.env.REACT_APP_BUY_LIMIT_ORDER) || item?.orderType === parseInt(process.env.REACT_APP_BUY_MARKET_ORDER)) && (
              <div className={styles.positive}>Buy</div>
            )}
            {(item?.orderType === parseInt(process.env.REACT_APP_SELL_LIMIT_ORDER) || item?.orderType === parseInt(process.env.REACT_APP_SELL_MARKET_ORDER)) && (
              <div className={styles.negative}>Sell</div>
            )}
          </div>
          {/* <div className={styles.col}>
            {(item?.orderType === parseInt(process.env.REACT_APP_BUY_LIMIT_ORDER) || item?.orderType === parseInt(process.env.REACT_APP_SELL_LIMIT_ORDER)) && (<div className={styles.info}>Limit</div>)}
            {(item?.orderType === parseInt(process.env.REACT_APP_BUY_MARKET_ORDER) || item?.orderType === parseInt(process.env.REACT_APP_SELL_MARKET_ORDER)) && (<div className={styles.info}>Market</div>)}
          </div> */}

          <div className={styles.col}>
            {item.orderType == process.env.REACT_APP_BUY_LIMIT_ORDER || item.orderType == process.env.REACT_APP_BUY_MARKET_ORDER ? (
              <div className={styles.positive}>{getDigitsAfterDecimal(item.price, item.priceDecimalPrecision)} <small>  {item?.slug.split("-")[1].toUpperCase()} </small> </div>
            ) :
              (<div className={styles.negative}>{getDigitsAfterDecimal(item.price, item.priceDecimalPrecision)} <small>  {item?.slug.split("-")[1].toUpperCase()} </small> </div>)}
          </div>

          <div className={styles.col}>
            {item.orderType == process.env.REACT_APP_BUY_LIMIT_ORDER || item.orderType == process.env.REACT_APP_BUY_MARKET_ORDER ? (
              <div className={styles.positive}>{getDigitsAfterDecimal(item.crypto_amount, item.amountDecimalPrecision)} <small> {item?.slug.split("-")[0].toUpperCase()} {item.crypto_exe_amount > 0 ? "(P.filled =" + item.crypto_exe_amount + ")" : null} </small>  </div>
            ) :
              (<div className={styles.negative}>{getDigitsAfterDecimal(item.crypto_amount, item.amountDecimalPrecision)} <small> {item?.slug.split("-")[0].toUpperCase()} {item.crypto_exe_amount > 0 ? "(P.filled =" + item.crypto_exe_amount + ")" : null} </small>  </div>)}
          </div>
          <div className={styles.col}>
            {item.orderType == process.env.REACT_APP_BUY_LIMIT_ORDER || item.orderType == process.env.REACT_APP_BUY_MARKET_ORDER ? (
              <div className={styles.positive}>{getDigitsAfterDecimal(item.fiat_amount, item.priceDecimalPrecision)} <small>  {item?.slug.split("-")[1].toUpperCase()} {item.fiat_exe_amount > 0 ? "(P.filled =" + item.fiat_exe_amount + ")" : null} </small>   </div>
            ) :
              (<div className={styles.negative}>{getDigitsAfterDecimal(item.fiat_amount, item.priceDecimalPrecision)} <small> {item?.slug.split("-")[1].toUpperCase()} {item.fiat_exe_amount > 0 ? "(P.filled =" + item.fiat_exe_amount + ")" : null} </small>   </div>)}
          </div>
          {/* <div className={styles.col}>
            {getDigitsAfterDecimal(item?.fiatFee, item?.priceDecimalPrecision)} <span className={styles.conv}> {item?.currency?.toUpperCase()}  </span>
          </div> */}
          <div className={styles.col}>
            {(item?.orderType === parseInt(process.env.REACT_APP_BUY_LIMIT_ORDER) || item?.orderType === parseInt(process.env.REACT_APP_BUY_MARKET_ORDER)) ?
              <>
                {getDigitsAfterDecimal(item?.fiatFee, item?.priceDecimalPrecision, true)} <small> {item?.currency?.toUpperCase()}  </small>
              </>
              :
              (item?.orderType === parseInt(process.env.REACT_APP_SELL_LIMIT_ORDER) || item?.orderType === parseInt(process.env.REACT_APP_SELL_MARKET_ORDER)) ?
                <>
                  {getDigitsAfterDecimal(item?.cryptoFee, item?.amountDecimalPrecision)} <small> {item?.coin?.toUpperCase()}  </small>
                </>
                :
                null}
          </div>
          <div className={styles.col}>
            <div className={styles.info}> {item.exeCrypto > 0 ? "(P.filled =" + item.exeCrypto + ")" : 'Open'}</div>
          </div>
          <div className={styles.col}>
            {
              (item?.trade_type === null) || parseInt(item?.trade_type) === parseInt(process.env.REACT_APP_TRADE_TYPE_SPOT) ?
                <div className={styles.info}>Spot</div>
                :
                parseInt(item?.margin_type) === parseInt(process.env.REACT_APP_CROSS_MARGIN) ?
                  <div className={styles.info}>Cross</div>
                  :
                  <div className={styles.info}>Isolated</div>
            }
          </div>
          <div className={styles.col}>
            {
              parseInt(item?.trade_type) != parseInt(process.env.REACT_APP_TRADE_TYPE_SPOT) ?
                parseInt(item?.orderType) === parseInt(process.env.REACT_APP_BUY_LIMIT_ORDER) || parseInt(item?.orderType) === parseInt(process.env.REACT_APP_BUY_MARKET_ORDER) ?
                  parseFloat(item?.fiat_exe_amount) > 0 ?
                    "N/A" :
                    <button
                      className={cn("button-small button-red", styles.button)}
                      onClick={() => {
                        setVisibleSuccessfully(true)
                      }}
                    >
                      Cancel
                    </button> :
                  parseInt(item?.orderType) === parseInt(process.env.REACT_APP_SELL_LIMIT_ORDER) || parseInt(item?.orderType) === parseInt(process.env.REACT_APP_SELL_MARKET_ORDER) ?
                    parseFloat(item?.crypto_exe_amount) > 0 ?
                      "N/A" :
                      <button
                        className={cn("button-small button-red", styles.button)}
                        onClick={() => {
                          setVisibleSuccessfully(true)
                        }}
                      >
                        Cancel
                      </button> :
                    ""
                :
                <button
                  className={cn("button-small button-red", styles.button)}
                  onClick={() => {
                    setVisibleSuccessfully(true)
                  }}
                >
                  Cancel
                </button>
            }
          </div>
        </div>
        <div className={styles.btns}>
          <div className={styles.col}>
            <div className={styles.info}> <span> Date: {toLocaleStringDateFormat(item.updatedAt)} </span></div>
          </div>
        </div>
      </div>
      <Modal
        visible={visibleSuccessfully}
        onClose={() => setVisibleSuccessfully(false)}
      >
        <Successfully
          item={item}
          closeModal={() => setVisibleSuccessfully(false)}
          cancelOrder={async (orderId) => cancelOpenOrder(orderId)}
        />
      </Modal>
    </>
  );
};

export default Item;
