import React, { useState } from "react";
import cn from "classnames";
import styles from "./Trade.module.sass";
import { Link } from "react-router-dom";
import Icon from "../../../../components/Icon";
import { getDigitsAfterDecimal, getPostiveNegativeNum } from "../../../../components/helper";
import TradeSkeleton from "../../../../components/Skeleton/TradeSkeleton";

const data = [
  {
    name: "1",
    price: 1000,
  },
  {
    name: "2",
    price: 2300,
  },
  {
    name: "3",
    price: 2000,
  },
  {
    name: "4",
    price: 2780,
  },
  {
    name: "5",
    price: 1890,
  },
  {
    name: "6",
    price: 2390,
  },
  {
    name: "7",
    price: 2490,
  },
  {
    name: "8",
    price: 3000,
  },
  {
    name: "9",
    price: 2500,
  },
  {
    name: "10",
    price: 2000,
  },
  {
    name: "11",
    price: 2780,
  },
  {
    name: "12",
    price: 1890,
  },
  {
    name: "13",
    price: 2390,
  },
  {
    name: "14",
    price: 1490,
  },
];

const Trade = ({ loading, dataMarkets, setDataMarkets }) => {
  const [search, setSearch] = useState("");
  const [order, setOrder] = useState("DSC");
  const [activeColumn, setActiveColumn] = useState({ key: "", value: false });

  const handleSort = (columnName) => {
    if (columnName === "name") {
      const sorted =
        order && order === "ASC"
          ? dataMarkets.sort((a, b) =>
            a["name"].toLowerCase() > b["name"].toLowerCase() ? 1 : -1
          )
          : dataMarkets.sort((a, b) =>
            a["name"].toLowerCase() < b["name"].toLowerCase() ? 1 : -1
          );
      setDataMarkets(sorted);
    }
    else if (columnName === "price") {
      const sorted =
        order && order === "ASC"
          ? dataMarkets.sort((a, b) =>
            a["currentMarketPrice"] > b["currentMarketPrice"] ? 1 : -1
          )
          : dataMarkets.sort((a, b) =>
            a["currentMarketPrice"] < b["currentMarketPrice"] ? 1 : -1
          );
      setDataMarkets(sorted);
    }
    else if (columnName === "dayChange") {
      const sorted =
        order && order === "ASC"
          ? dataMarkets.sort((a, b) =>
            a["dayChange"] > b["dayChange"] ? 1 : -1
          )
          : dataMarkets.sort((a, b) =>
            a["dayChange"] < b["dayChange"] ? 1 : -1
          );
      setDataMarkets(sorted);
    }
    else if (columnName === "weekChange") {
      const sorted =
        order && order === "ASC"
          ? dataMarkets.sort((a, b) =>
            a["weekChange"] > b["weekChange"] ? 1 : -1
          )
          : dataMarkets.sort((a, b) =>
            a["weekChange"] < b["weekChange"] ? 1 : -1
          );
      setDataMarkets(sorted);
    }
    else if (columnName === "marketCap") {
      const sortedData = [...dataMarkets].sort((a, b) => {
        const capA = parseInt(a?.cap?.replace(/,/g, ''), 10);
        const capB = parseInt(b?.cap?.replace(/,/g, ''), 10);
        return order === 'ASC' ? capA - capB : capB - capA;
      });
      setDataMarkets(sortedData);
    }
    else if (columnName === "volume") {
      const sortedData = [...dataMarkets].sort((a, b) => {
        const volumeA = parseInt(a?.volume?.replace(/,/g, ''), 10);
        const volumeB = parseInt(b?.volume?.replace(/,/g, ''), 10);
        return order === 'ASC' ? volumeA - volumeB : volumeB - volumeA;
      });
      setDataMarkets(sortedData);
    }
    const sortingType = order && order === "ASC" ? "DSC" : "ASC";
    setOrder(sortingType);
    setActiveColumn({ key: columnName, value: true });
  };

  return (
    <div className={styles.trade}>
      <form className={styles.form}>
        <input
          className={styles.input}
          type="text"
          onChange={(e) => setSearch(e.target.value)}
          name="search"
          placeholder="Search coin"
          required
        />
        <button onClick={(e) => { e.preventDefault() }} className={styles.result}>
          <Icon name="search" size="20" />
        </button>
      </form>
      <div className={styles.table}>
        <div className={styles.row}>
          <div className={styles.col}>
            <div ></div>
          </div>
          <div className={styles.col}>
            <div className={cn("sorting", { [styles.activeColumn]: activeColumn.key === "name" })}
              onClick={() => { handleSort("name") }}>
              Name
            </div>
          </div>
          <div className={styles.col}>
            <div className={cn("sorting", { [styles.activeColumn]: activeColumn.key === "price" })}
              onClick={() => { handleSort("price") }}>
              Price
            </div>
          </div>
          <div className={styles.col}>
            <div className={cn("sorting", { [styles.activeColumn]: activeColumn.key === "dayChange" })}
              onClick={() => { handleSort("dayChange") }}>
              24h %
            </div>
          </div>
          <div className={styles.col}>
            <div
              className={cn("sorting", { [styles.activeColumn]: activeColumn.key === "weekChange" })}
              onClick={() => { handleSort("weekChange") }}
            >
              7d %
            </div>
          </div>
          <div className={styles.col}>
            <div
            // className={cn("sorting", { [styles.activeColumn]: activeColumn.key === "marketCap" })}
            // onClick={() => { handleSort("marketCap") }}
            >
              Marketcap
              <Icon name="coin" size="20" />
            </div>
          </div>
          <div className={styles.col}>
            <div
            // className={cn("sorting", { [styles.activeColumn]: activeColumn.key === "volume" })}
            // onClick={() => { handleSort("volume") }}
            >
              Volume (24h) <Icon name="chart" size="20" />
            </div>
          </div>
          {/* <div className={styles.col}>Chart</div> */}
          <div className={styles.col}>Action</div>
        </div>
        {loading ? <TradeSkeleton rowCount={30} colCount={8} />
          :
          dataMarkets?.map((i, ind) => {
            return (search !== "" &&
              i.name.toLowerCase().indexOf(search.toLowerCase()) ===
              -1 && i.slug.toLowerCase().indexOf(search.toLowerCase()) ===
              -1) ? null : (
              <div className={styles.row} key={ind}>
                <div className={styles.col}>
                  <div className={styles.line}>  {ind + 1} </div>
                </div>
                <div className={styles.col}>
                  <div className={styles.item}>
                    <div className={styles.icon}>
                      <img src={i.icon} alt="Coin" />
                    </div>
                    <div className={styles.details}>
                      <span className={styles.subtitle}>{i.name}</span>
                      <span className={styles.currency}>{i.symbol}</span>
                    </div>
                  </div>
                </div>
                <div className={styles.col}>
                  <div className={styles.label}>Price</div>
                  {getDigitsAfterDecimal(i.currentMarketPrice, i.priceDecimalPrecision)}
                </div>
                <div className={styles.col}>
                  {getPostiveNegativeNum(i.dayChange) ?
                    <div className={styles.positive}>{getDigitsAfterDecimal(i.dayChange, 2)}%</div>
                    :
                    <div className={styles.negative}>{getDigitsAfterDecimal(i.dayChange, 2)}%</div>
                  }
                </div>
                <div className={styles.col}>
                  {getPostiveNegativeNum(i.weekChange) ?
                    <div className={styles.positive}>{getDigitsAfterDecimal(i.weekChange, 2)}%</div>
                    :
                    <div className={styles.negative}>{getDigitsAfterDecimal(i.weekChange, 2)}%</div>
                  }
                </div>
                <div className={styles.col}>
                  <div className={styles.label}>Marketcap</div>
                  {i.cap}
                </div>
                <div className={styles.col}>
                  <div className={styles.label}>Volume (24h)</div>
                  {i.volume}
                </div>
                <div className={styles.col}>
                  <Link className={cn("button-small", styles.button)} to={`/exchange/${i.slug}`}>
                    Trade
                  </Link>
                </div>
              </div>
            )
          })}
      </div>
    </div>
  );
};
export default Trade;