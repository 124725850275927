import React, { useState } from "react";
import cn from "classnames";
import styles from "./Balance.module.sass";
import Icon from "../../../components/Icon";
import { getDigitsAfterDecimal } from "../../../components/helper";
import OrderBookSkeleton from "../../../components/Skeleton/OrderBookSkeleton";

const sorting = [
  {
    color1: "#FF6838",
    color2: "#B1B5C3",
    color3: "#58BD7D",
  },
  {
    color1: "#B1B5C3",
    color2: "#B1B5C3",
    color3: "#58BD7D",
  },
  {
    color1: "#FF6838",
    color2: "#B1B5C3",
    color3: "#B1B5C3",
  },
];

const Balance = ({
  buy,
  sell,
  currentMarketDetails,
  loading,
  currentMarketPrice,
  tradingType,
}) => {

  const priceMax = sell.length > 0 ? Math.max(...sell.map((i) => i.fiatPrice)) : parseFloat(currentMarketDetails[0]?.currentMarketPrice);

  const [showOption, setshowOption] = useState(0);
  const [limitOption, setLimitOption] = useState(13);
  const [startOption, setStartOption] = useState(0);
  const topBtnHandler = (index) => {
    setshowOption(index)
    if (index === 1 || index === 2)
      setLimitOption(26)
    else
      setLimitOption(13)
  }

  const getTranslateValue = (remFiat) => {

    let calcValue = (100 * (parseFloat(remFiat) / parseFloat(priceMax)));
    if (calcValue >= 100)
      return 100;
    else
      return calcValue;
  }

  return (
    <div className={styles.balance}>
      <div className={styles.head}>
        <div className={styles.sorting}>
          {sorting.map((x, index) => (
            <button
              className={cn(styles.direction, { [styles.active]: index === showOption })}
              key={index} onClick={() => topBtnHandler(index)}
            >
              <span style={{ backgroundColor: x.color1 }}></span>
              <span style={{ backgroundColor: x.color2 }}></span>
              <span style={{ backgroundColor: x.color3 }}></span>
            </button>
          ))}
        </div>
        {/* <Dropdown
          className={styles.dropdown}
          classDropdownHead={styles.dropdownHead}
          classDropdownArrow={styles.dropdownArrow}
          classDropdownBody={styles.dropdownBody}
          classDropdownOption={styles.dropdownOption}
          value={counter}
          setValue={setCounter}
          options={counterOptions}
        /> */}
      </div>
      <div className={styles.top}>
        <div className={styles.price}>Price ({currentMarketDetails[0]?.currency?.toUpperCase()})</div>
        <div className={styles.amount}>Amount ({currentMarketDetails[0]?.coin?.toUpperCase()})</div>
        <div className={styles.total}>Total ({currentMarketDetails[0]?.currency?.toUpperCase()})  </div>
      </div>
      {/* <div className={styles.list}>
        {(showOption === 0 || showOption === 2) && (
          loading ? <OrderBookSkeleton rowCount={13} />
            :
            sell.slice(0, limitOption).sort((a, b) => parseFloat(a.fiatPrice) < parseFloat(b.fiatPrice) ? 1 : -1).map((x, index) =>
              <div className={cn(styles.item, { [styles.negative]: true })} key={index}>
                <div className={styles.price}>{getDigitsAfterDecimal(x.fiatPrice, x.priceDecimalPrecision)} </div>
                <div className={styles.amount}> {getDigitsAfterDecimal(x.remcrypto, x.amountDecimalPrecision)}</div>
                <div className={styles.total}>{getDigitsAfterDecimal(x.remfiat, x.priceDecimalPrecision)} </div>
                <div className={styles.progressAsk} style={{ transform: "translateX(-" + getTranslateValue(x.remfiat) + "%)", left: "100%" }}></div>
              </div>
            )
        )}

        <div className={cn(styles.statistics, styles.positive)} >
          <div className={styles.currency}>{currentMarketDetails && currentMarketDetails[0]?.currentMarketPrice ? getDigitsAfterDecimal(currentMarketDetails[0]?.currentMarketPrice, currentMarketDetails[0]?.priceDecimalPrecision) : null}</div>
          <Icon name="arrow-top" size="16" />
        </div>

        {(showOption === 0 || showOption === 1) && (
          loading ? <OrderBookSkeleton rowCount={13} />
            :
            buy.slice(0, limitOption).sort((a, b) => parseFloat(a.fiatPrice) < parseFloat(b.fiatPrice) ? 1 : -1).map((x, index) =>
              <div className={cn(styles.item, { [styles.positive]: true })} key={index} >
                <div className={styles.price}>{getDigitsAfterDecimal(x.fiatPrice, x.priceDecimalPrecision)}</div>
                <div className={styles.amount}>{getDigitsAfterDecimal(x.remcrypto, x.amountDecimalPrecision)}</div>
                <div className={styles.total}>{getDigitsAfterDecimal(x.remfiat, x.priceDecimalPrecision)}</div>
                <div className={styles.progressBid} style={{ transform: "translateX(-" + getTranslateValue(x.remfiat) + "%)", left: "100%" }}></div>
              </div>
            )
        )}
      </div> */}
      {tradingType?.toLowerCase() === "cross" ?
        <div className={styles.list}>
          {(showOption === 0 || showOption === 2) && (
            loading ? <OrderBookSkeleton rowCount={16} />
              :
              // sell.slice(sell.length > 0 && sell.length > limitOption ? sell.length - limitOption : startOption, sell.length > 0 && sell.length > limitOption ? limitOption + (sell.length - limitOption) : limitOption).sort((a, b) => parseFloat(a.fiatPrice) < parseFloat(b.fiatPrice) ? 1 : -1).map((x, index) =>
              sell.slice(0, 16).sort((a, b) => parseFloat(a.fiatPrice) < parseFloat(b.fiatPrice) ? 1 : -1).map((x, index) =>
                <div className={cn(styles.item, { [styles.negative]: true })} key={index}>
                  <div className={styles.price}>{getDigitsAfterDecimal(x.fiatPrice, x.priceDecimalPrecision)} </div>
                  <div className={styles.amount}> {getDigitsAfterDecimal(x.remcrypto, x.amountDecimalPrecision)}</div>
                  <div className={styles.total}>{getDigitsAfterDecimal(x.remfiat, 2)} </div>
                  <div className={styles.progressAsk} style={{ transform: "translateX(-" + getTranslateValue(x.remfiat) + "%)", left: "100%" }}></div>
                </div>
              )
          )}

          <div className={cn(styles.statistics, styles.positive)} >
            {/* <div className={styles.currency}>{currentMarketDetails && currentMarketDetails[0]?.currentMarketPrice ? getDigitsAfterDecimal(currentMarketDetails[0]?.currentMarketPrice, currentMarketDetails[0]?.priceDecimalPrecision) : null}</div> */}
            <div className={styles.currency}>{(currentMarketPrice?.currentMarketPrice === null || currentMarketPrice?.currentMarketPrice === undefined) ? currentMarketDetails && currentMarketDetails[0]?.currentMarketPrice ? getDigitsAfterDecimal(currentMarketDetails[0]?.currentMarketPrice, currentMarketDetails[0]?.priceDecimalPrecision) : "" : currentMarketPrice?.currentMarketPrice}</div>
            <Icon name="arrow-top" size="16" />
            {/* <div className={styles.money}>{currentMarketDetails && currentMarketDetails[0]?.currentMarketPrice ? getDigitsAfterDecimal(currentMarketDetails[0]?.currentMarketPrice, currentMarketDetails[0]?.priceDecimalPrecision) : null}</div> */}
          </div>

          {(showOption === 0 || showOption === 1) && (
            loading ? <OrderBookSkeleton rowCount={16} />
              :
              buy.slice(0, 16).sort((a, b) => parseFloat(a.fiatPrice) < parseFloat(b.fiatPrice) ? 1 : -1).map((x, index) =>
                <div className={cn(styles.item, { [styles.positive]: true })} key={index} >
                  <div className={styles.price}>{getDigitsAfterDecimal(x.fiatPrice, x.priceDecimalPrecision)}</div>
                  <div className={styles.amount}>{getDigitsAfterDecimal(x.remcrypto, x.amountDecimalPrecision)}</div>
                  <div className={styles.total}>{getDigitsAfterDecimal(x.remfiat, 2)}</div>
                  <div className={styles.progressBid} style={{ transform: "translateX(-" + getTranslateValue(x.remfiat) + "%)", left: "100%" }}></div>
                </div>
              )
          )}
        </div>
        :
        <div className={styles.list}>
          {(showOption === 0 || showOption === 2) && (
            loading ? <OrderBookSkeleton rowCount={13} />
              :
              // sell.slice(sell.length > 0 && sell.length > limitOption ? sell.length - limitOption : startOption, sell.length > 0 && sell.length > limitOption ? limitOption + (sell.length - limitOption) : limitOption).sort((a, b) => parseFloat(a.fiatPrice) < parseFloat(b.fiatPrice) ? 1 : -1).map((x, index) =>
              sell.slice(0, limitOption).sort((a, b) => parseFloat(a.fiatPrice) < parseFloat(b.fiatPrice) ? 1 : -1).map((x, index) =>
                <div className={cn(styles.item, { [styles.negative]: true })} key={index}>
                  <div className={styles.price}>{getDigitsAfterDecimal(x.fiatPrice, x.priceDecimalPrecision)} </div>
                  <div className={styles.amount}> {getDigitsAfterDecimal(x.remcrypto, x.amountDecimalPrecision)}</div>
                  <div className={styles.total}>{getDigitsAfterDecimal(x.remfiat, 2)} </div>
                  <div className={styles.progressAsk} style={{ transform: "translateX(-" + getTranslateValue(x.remfiat) + "%)", left: "100%" }}></div>
                </div>
              )
          )}

          <div className={cn(styles.statistics, styles.positive)} >
            {/* <div className={styles.currency}>{currentMarketDetails && currentMarketDetails[0]?.currentMarketPrice ? getDigitsAfterDecimal(currentMarketDetails[0]?.currentMarketPrice, currentMarketDetails[0]?.priceDecimalPrecision) : null}</div> */}
            <div className={styles.currency}>{(currentMarketPrice?.currentMarketPrice === null || currentMarketPrice?.currentMarketPrice === undefined) ? currentMarketDetails && currentMarketDetails[0]?.currentMarketPrice ? getDigitsAfterDecimal(currentMarketDetails[0]?.currentMarketPrice, currentMarketDetails[0]?.priceDecimalPrecision) : "" : currentMarketPrice?.currentMarketPrice}</div>
            <Icon name="arrow-top" size="16" />
            {/* <div className={styles.money}>{currentMarketDetails && currentMarketDetails[0]?.currentMarketPrice ? getDigitsAfterDecimal(currentMarketDetails[0]?.currentMarketPrice, currentMarketDetails[0]?.priceDecimalPrecision) : null}</div> */}
          </div>

          {(showOption === 0 || showOption === 1) && (
            loading ? <OrderBookSkeleton rowCount={13} />
              :
              buy.slice(0, limitOption).sort((a, b) => parseFloat(a.fiatPrice) < parseFloat(b.fiatPrice) ? 1 : -1).map((x, index) =>
                <div className={cn(styles.item, { [styles.positive]: true })} key={index} >
                  <div className={styles.price}>{getDigitsAfterDecimal(x.fiatPrice, x.priceDecimalPrecision)}</div>
                  <div className={styles.amount}>{getDigitsAfterDecimal(x.remcrypto, x.amountDecimalPrecision)}</div>
                  <div className={styles.total}>{getDigitsAfterDecimal(x.remfiat, 2)}</div>
                  <div className={styles.progressBid} style={{ transform: "translateX(-" + getTranslateValue(x.remfiat) + "%)", left: "100%" }}></div>
                </div>
              )
          )}
        </div>
      }
    </div>
  );
};

export default Balance;
