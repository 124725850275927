import React from "react";
import cn from "classnames";
import styles from "./Successfully.module.sass";
import { firstLetterCapitalHandler } from "../../helper";
import LoaderScreen from "../../LoaderScreen";

const Successfully = ({ userSwitchRegion, loading, userStatus, setVisibleModal }) => {

  return (
    <>
      {loading && <LoaderScreen />}
      <div className={styles.successfully}>
        <div className={cn("h4", styles.title)}>
          Switch to Bitdenex {firstLetterCapitalHandler(userStatus?.exchangeName)} Exchange!
        </div>
        <div className={cn("h5", styles.title)}>
          Are you sure you want to switch account?
        </div>
        <div className={cn("h6", styles.title)}>
          *Additional local T&C's may apply!
        </div>
        <div className={styles.btns}>
          <button
            className={cn("button-small button-red", styles.button)}
            onClick={(e) => {
              e.preventDefault();
              setVisibleModal(false);
            }}
          >
            No
          </button>
          <button
            className={cn("button-small button-green", styles.button)}
            onClick={(e) => {
              e.preventDefault();
              userSwitchRegion();
            }}
          >
            Yes
          </button>
        </div>
      </div>
    </>
  );
};

export default Successfully;
