import React from "react";
import cn from "classnames";
import styles from "./Successfully.module.sass";
import LoaderScreen from "../../../components/LoaderScreen";

const ConfirmationModal = ({
  closeOpenPositionHandler,
  item,
  setConfirmationModal,
  setCloseButtonStatus,
  closePositionLoading
}) => {
  return (
    <>
      {closePositionLoading && <LoaderScreen />}
      <div className={styles.successfully}>
        <div className={styles.info}>
          Are you sure to Close position?
        </div>
        <div className={styles.info}>

        </div>
        <div className={styles.btns}>
          <button
            className={cn("button-small button-red", styles.button)}
            onClick={() => {
              setConfirmationModal(false);
            }}
          >
            No
          </button>
          <button
            className={cn("button-small button-green", styles.button)}
            onClick={() => {
              setCloseButtonStatus(true);
              closeOpenPositionHandler(item?.orderId);
            }}
          >
            Yes
          </button>
        </div>
      </div>
    </>
  );
};

export default ConfirmationModal;
