import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import Main from "./Main";
import Learn from "./Learn";
import Trend from "./Trend";
import Popular from "../../components/Popular";
import Download from "./Download";
import News from "../../components/News";
import Steps from "./Steps";
import requestHandler from "../../actions/httpClient";
import { userStatusCreator } from "../../actions/getUserStatus";
import { useNavigate } from "react-router";
import { socket } from "../../socket";

const Home = ({ checkSignature }) => {
  const scrollToRef = useRef(null);
  const dispatch = useDispatch();
  const [marketsData, setMarketsData] = useState([]);
  const [loading, setLoading] = useState(true)
  const navigate = useNavigate();

  useEffect(() => {
    window.history.pushState(null, null, window.location.href);
    window.onpopstate = function (event) {
      navigate(1);
    };
    if (localStorage.getItem('signature') && localStorage.getItem('signature') !== null
      && localStorage.getItem('jwt_token') && localStorage.getItem('jwt_token') !== null
      && localStorage.getItem('switch_exchange') && localStorage.getItem('switch_exchange') !== null) {
      // getRegion();
      dispatch(userStatusCreator());
    }
  }, [checkSignature]);

  useEffect(() => {
    socket.connect();

    socket.on("international_markets_web", (data) => {
      setLoading(false);
      const sorted = data?.find((x) => x?.currency?.toLowerCase() === "usdt")?.markets;
      setMarketsData(sorted);
    });

    return (() => {
      socket.off("international_markets_web");
      socket.disconnect();
    });
  }, []);

  const getRegion = async () => {
    try {
      const regionPayload = await requestHandler("region", "post");
      for (let i = 0; i < regionPayload.data?.data.length; i++) {
        if (regionPayload?.data?.data[i].slug == process.env.REACT_APP_INTERNATIONAL_MARKETS) {
          getMarkets(regionPayload?.data?.data[i]?.id);
        }
      }
    }
    catch (e) {
    };
  };

  const getMarkets = async (id) => {
    try {
      setLoading(true)
      let data = {
        type: "randomMarkets",
        region: id,
        signature: localStorage.getItem('signature')
      };
      const homePagePayload = await requestHandler("get_markets", "post", data, "jwt_token");
      setMarketsData(homePagePayload?.data?.data);
      setLoading(false)
    }
    catch (e) {
      setLoading(false);
      window.location.href = process.env.REACT_APP_GLOBAL_URL;
    };
  };

  return (
    <>
      <Main scrollToRef={scrollToRef} marketsData={marketsData} loading={loading} />
      <Trend marketsData={marketsData} loading={loading} />
      <Learn scrollToRef={scrollToRef} />
      <Popular classSection="section-bg section-mb0" />
      <Download />
      <News classSection="section-bg" />
      <Steps />
    </>
  );
};

export default Home;
