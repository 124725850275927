import React, { useState } from "react";
import Profile from "../../components/Profile";
import Form from "./Form";
import Finish from "./Finish";
import requestHandler from "../../actions/httpClient";
import { addNotification } from "../../components/Notification";
import { useNavigate } from "react-router";
import LoaderScreen from "../../components/LoaderScreen";

const breadcrumbs = [
  {
    title: "Home",
    url: "/",
  },
  {
    title: "Change password",
  },
];

const ChangePassword = () => {
  const [finish, setFinish] = useState(false);
  // const [successData, setSuccessData] = useState([]);
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate();

  const changePasswordHandler = async (oldPassword, newPassword, confirmPassword) => {
    setLoading(true)
    try {
      let formData = {
        signature: localStorage.getItem("signature"),
        current_password: oldPassword,
        password: newPassword,
        cnf_password: confirmPassword
      };
      const changePasswordPayload = await requestHandler("change_password", "post", formData, "jwt_token");
      setLoading(false)
      if (changePasswordPayload && changePasswordPayload.status === 200) {
        addNotification({
          title: "Success",
          message: changePasswordPayload?.data?.message[0].msg,
          type: "Success"
        });
        navigate("/profile-info");
      }
    }
    catch (e) {
      setLoading(false)
      addNotification({
        title: "Error",
        message: e?.data?.message[0]?.msg,
        type: "danger"
      });
    };
  };

  return (
    <Profile title="Change password" breadcrumbs={breadcrumbs}>
      {finish ? <Finish /> : <Form
        changePasswordHandler={changePasswordHandler}
        goFinish={() => setFinish(true)} />}
      {loading && <LoaderScreen />}
    </Profile>
  );
};

export default ChangePassword;
